<template>
    <b-container fluid>
      <b-row>
        <b-navbar toggleable="lg" type="dark" variant="dark">
          <b-navbar-brand href="#"> DID Range Tool</b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item href="#" v-b-modal.modal-1>Help</b-nav-item>
            </b-navbar-nav>
          </b-collapse> 
        </b-navbar>
      </b-row>
      <b-row>
        <b-col>
          <i>Magic tool for phone guys</i>
          <p>--</p>
          <b-modal id="modal-1" title="Help" ok-only>
            <img alt="Vue logo" src="../assets/picto_numunzip.png"><br/><br/>
            <p><b>Case 1 : </b><i>Use the left TextArea to insert your e.164 phone number ranges and get back all phones numbers in the right Textarea</i></p>
            <p><b>Case 2 : </b><i>Use the right TextArea to insert all your e.164 phones number and get back all ranges available in the left TextArea</i></p>
            <p><b>Note : </b><i>Dataset with mixed phones number and phone numbers ranges are allowed</i></p>
          </b-modal>
        </b-col>
      </b-row>
      <b-row>     
        <br />
          <b-col>
            <b-form-textarea
              id="textarea1"
              v-model="textArea1"
              placeholder="Enter phone number ranges here, eq: +334020304[50-59], 1 range per row"
              rows="10"
              max-rows="20"
              @input="handleUnzippedDID"
            ></b-form-textarea>
          </b-col>
          <b-col>
            <b-form-textarea
            id="textarea2"
            v-model="textArea2"
            placeholder="Enter your phones number here, 1 phone number per row"
            rows="10"
            max-rows="20"
            @input="handleZippedDID"
          ></b-form-textarea>
          </b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'DidExtractor',
  props: {
    msg: String
  },
  data() {
    return {
      textArea1: '',
      textArea2: '',
      unzippedDid: [],
      zippedDid: [],
      regexPhoneNumber: /[a-zA-Z]+/g,
    }
  },
  methods: {
    myFunction(test){
      alert(test)
    },
    handleUnzippedDID() {
      this.unzippedDid = []
      this.textArea2 = this.unzipSdas(this.textArea1.toLowerCase().split("\n")).join("\r\n")
    },
    handleZippedDID() {
      this.zippedDid = []
      this.textArea1 = this.zipSdas(this.textArea2.toLowerCase().split("\n")).join("\r\n")
    },
    // Build range of DiDs (create [])
    zipSdas(sdas) {

      let tempArray = sdas.sort()
      let returnedArray = []

      while (tempArray.length > 0) {
        let counter = 0
        let baseNumber = ""
        let currentNumber = ""
        let nextNumber = ""
        let start = ""
        let end = ""
        let numberRange = ""
        let prefix = ""

        baseNumber = tempArray[0].toString()
        for (let i = 0 ; i < tempArray.length; i++) {
          
          // if it's not the last Item
          if (i < tempArray.length) {

            currentNumber = parseInt(tempArray[i])
            nextNumber = parseInt(tempArray[i + 1])

            if ((currentNumber + 1) == nextNumber) {
              counter = counter + 1
            }
            else
            {
              // this is a range
              if (counter != 0) {

                start = this.findDiff(tempArray[counter].toString(),baseNumber.toString())
                end = this.findDiff(baseNumber.toString(),tempArray[counter].toString())
              
                prefix = baseNumber.substring(0, baseNumber.length - start.length)

                numberRange = prefix + "[" + start + "-" + end + "]" 

                // arrayPush
                returnedArray.push(numberRange)

                // Array Cleanup
                tempArray.splice(0, (counter + 1))
                  
                // stop the for loop
                break
              }
              // single Number
              else {
                returnedArray.push(baseNumber.toString())
                tempArray.splice(0, (counter + 1))
                // stop the for loop
                break
              }
            }
          }
          // last Item
          else
          {
            returnedArray.push(baseNumber.toString())
            tempArray.splice(0, 1)
            // stop the for loop
            break
          }

        }
        console.log(tempArray)
      }
      return returnedArray
    },
    // Extract DID Ranges (with [])
    unzipSdas(sdas) {
      let returnedArray = []

      for (let i = 0 ; i < sdas.length; i++)
      {
        // On vérifie que le contenu des Sdas saisies par le user.
        if (!sdas[i].match(this.regexPhoneNumber))
        {
          
          sdas[i] = sdas[i].replace(/\s/g, '');
        
          // A la recherche du crochet
          if (sdas[i].indexOf('[') != -1 )
          {
            
            // On recupère la pos du crochet ouvrant dans la str
            let startPos = sdas[i].indexOf("[")
            
            // On recupère la pos du crochet fermant dans la str
            let endPos = sdas[i].indexOf("]")
            
            // On extrait ce qu'il y a à l'intérieur des crochets.
            let zipPart = sdas[i].substring(startPos+1,endPos)
            zipPart = zipPart.split('-')
            
            // On vérifie que ce qu'il y a dans le crochet est cohérent
            if ((!zipPart[1] <= 99) && (zipPart[1] <= zipPart[0]))
            {
              console.log("break!!! tu fais quoi ?")
              break
            }
            
            // On recupère la partie principale du numéro avant crochets
            let mainPart = sdas[i].split('[')[0].replace(/^0/, "+33")
            
            for (let i = zipPart[0]; i <= zipPart[1]; i++) {
              const numeroFormate = i.toString().padStart(zipPart[0].length, '0');
              returnedArray.push(mainPart.concat(numeroFormate));
            }
          }
          // pas de crochet ce n'est pas une tranche
          else
          { 
            returnedArray.push(sdas[i])
          }
        }
      }		
      
      // On retourne un tableau sans doublon
      return returnedArray.filter((item, index) => returnedArray.indexOf(item) === index)
    },
    // Compare 2 strings, return mismatching chars of str1
    findDiff(str1, str2) {
      let diff=""
      str2.split('').forEach(function(val, i) {
        if (val != str1.charAt(i))
        diff += val ;
      })
      return diff
    },
  }
}
</script>